<template>
  <v-card>
    <v-card-title class="title">
      Create variable
    </v-card-title>
    <v-card-text class="pt-0">
      <v-form @submit="handleSubmit">
        <v-text-field
          v-model="variable.name"
          required
          label="Name"
          placeholder="Give the variable a short name to refer to in formulas"
        />
        <v-text-field
          v-model="variable.prettyName"
          required
          label="User facing name"
          placeholder="This is the name users will see"
        />
        <v-textarea
          v-model="variable.description"
          rows="3"
          required
          label="Description"
        />
        <v-select
          v-model="variable.type"
          label="Type of variable"
          :items="[
            { text: 'Multiple choice', value: 'options' },
            { text: 'Numeric', value: 'numeric' }
          ]"
        />
        <v-combobox
          v-if="variable.type === 'options'"
          v-model="variable.options"
          :items="[]"
          label="Possible values"
          placeholder="Type a value and press enter to add it"
          multiple
          chips
        />
        <v-select
          v-if="variable.options.length > 0"
          v-model="variable.value"
          :items="variable.options"
          label="Default value"
          :disabled="variable.options.length === 0"
          placeholder="What should be this variable's value when first added to a project?"
        />
        <v-text-field
          v-if="variable.type === 'numeric'"
          v-model="variable.value"
          label="Default value"
          type="number"
          placeholder="Variable's value when first added to a project"
        />
        <v-row no-gutters>
          <v-spacer />
          <v-btn
            dark
            large
            elevation="0"
            @click="handleSubmit"
          >
            Create
          </v-btn>
        </v-row>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: 'NewVariableForm',
  data () {
    return {
      variable: {
        name: '',
        prettyName: '',
        description: '',
        type: null,
        options: [],
        value: null
      }
    }
  },
  watch: {
    'variable.options': function (newVal, oldVal) {
      if (oldVal.length === 0 && newVal.length > 0) {
        this.variable.value = newVal[0]
      }
    },
    'variable.type': function (newVal) {
      this.variable.options = []
      this.variable.value = newVal === 'numeric' ? 0 : null
    }
  },
  methods: {
    async createVariable () {
      try {
        await this.$axios.post('/variables', { ...this.variable, defaultValue: this.variable.value })
        this.$emit('created')
      } catch (err) {
        if (err.response.status === 400) {
          this.$store.dispatch('showSnackbar', { color: 'error', text: err.response.data.message })
        }
        throw err
      }
    },
    resetForm () {
      this.variable = { name: '', description: '', options: [], value: null }
    },
    async handleSubmit () {
      await this.createVariable()
      this.resetForm()
    },
    addOption () {
      this.options.push({
        text: '',
        value: ''
      })
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
