<template>
  <v-card>
    <v-data-table
      :headers="headers"
      :items="variables"
      :items-per-page="10"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title class="headline pb-0 pt-2">
            Variables
          </v-toolbar-title>
          <v-spacer />
          <v-dialog
            v-model="showForm"
            max-width="500"
          >
            <template #activator="{ on }">
              <v-btn
                color="shamrock"
                dark
                class="mb-2"
                elevation="0"
                v-on="on"
              >
                New variable
              </v-btn>
            </template>
            <NewVariableForm
              @created="handleCreatedVariable"
            />
          </v-dialog>
        </v-toolbar>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-btn
          small
          text
          color="crimson"
          @click="deleteVariable(item.name)"
        >
          <v-icon
            left
            small
          >
            mdi-delete
          </v-icon>
          Delete
        </v-btn>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import NewVariableForm from './NewVariableForm'
import { mapActions } from 'vuex'

export default {
  name: 'AdminVariables',
  components: {
    NewVariableForm
  },
  data () {
    return {
      headers: [
        {
          text: 'Name',
          value: 'name'
        },
        {
          text: 'User facing name',
          value: 'prettyName'
        },
        {
          text: 'Type',
          value: 'type'
        },
        {
          text: 'Description',
          value: 'description'
        },
        {
          text: 'Options',
          value: 'options',
          sortable: false
        },
        {
          value: 'actions',
          sortable: false
        }
      ],
      variables: [],
      showForm: false
    }
  },
  created () {
    this.fetchVariables()
  },
  methods: {
    ...mapActions(['showSnackbar']),

    async fetchVariables () {
      const { data: variables } = await this.$axios.get('/variables')
      this.variables = variables.map(variable => ({
        ...variable,
        value: variable.defaultValue,
        options: variable.options.join(', ')
      }))
    },
    async deleteVariable (variableName) {
      try {
        await this.$axios.delete(`/variables/${variableName}`)
        this.fetchVariables()
      } catch (err) {
        this.showSnackbar({ color: 'error', message: 'Unable to delete variable. Please try again.' })
        throw err
      }
    },
    handleCreatedVariable () {
      this.showForm = false
      this.fetchVariables()
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
